import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {ResetPassword} from './components/newPassword'
import OTPForm from './components/OtpForm'
import {SuccessFull} from './components/success'
import {SecurityError} from './components/SecurityError'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
    <Route path='forgot-password' element={<ForgotPassword />} />
    <Route path='new-password/verification/:id' element={<ResetPassword />} />
    {/* <Route path='otp/verification/:id' element={<OTPForm />} /> */}
    <Route path='otp/verification' element={<OTPForm />} />

    <Route path='success' element={<SuccessFull />} />
    <Route path='error-otp' element={<SecurityError />} />
  </Routes>
)

export {AuthPage}
