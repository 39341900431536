import React from 'react'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

type Props = {
  className: string
  total: string
  color: string
  title: string
  subtitle: string
  description: string
  profilePictures: string[] // Add profilePictures prop
}

const StatsAlt: React.FC<Props> = ({
  className,
  total,
  color,
  title,
  subtitle,
  description,
  profilePictures,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body my-3'>
        <div className='py-1'>
          <span className='text-dark fs-1 fw-bold me-2'>{total}</span>
          <span className='card-title fw-bold text-muted fs-7 mb-3 d-block'>{description}</span>
        </div>

        <div className='d-flex flex-column mt-6'>
          <div className='text-dark me-2 fw-bold pb-4'>Total users</div>

          <div className='d-flex ps-4'>
            {profilePictures.map((profilePicture, index) => (
              <a
                href='#'
                className='symbol symbol-35px symbol-circle ms-n4'
                data-bs-toggle='tooltip'
                title={`User ${index + 1}`}
                key={index}
              >
                <img src={toAbsoluteUrl(profilePicture)} alt={`User ${index + 1}`} />
              </a>
            ))}
          </div>
        </div>
      </div>
      {/* end:: Body */}
    </div>
  )
}

export {StatsAlt}
