import {createSlice} from '@reduxjs/toolkit'

export interface CouponCodeState {
  message: string
}

const initialState: CouponCodeState = {
  message: '',
}

const couponCodeStateSlice = createSlice({
  name: 'couponCodeState',
  initialState,
  reducers: {
    addCouponCode: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateCouponCode: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteCouponCode: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addCouponCode, updateCouponCode, deleteCouponCode} = couponCodeStateSlice.actions

export default couponCodeStateSlice.reducer
