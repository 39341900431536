import {useEffect, useState} from 'react'
import {KTIcon, useDebounce} from '../../../../_metronic/helpers'
import {UsersListFilter} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import AddNewCustomer from './AddNewCustomer'
import {User} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import * as XLSX from 'xlsx'
import moment from 'moment'

const TableToolbar = ({data}) => {
  return (
    <div>
      <ToolbarActions data={data} />
    </div>
  )
}

const ToolbarActions = ({data}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isUserLoading, setUserLoading] = useState(true)

  const openAddUserModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const getUser = () => {
    setUserLoading(true)
    setTimeout(() => {
      setUserLoading(false)
    }, 1000)
  }
  const [selectedRole, setSelectedRole] = useState<string>('')
  useEffect(() => {
    getUser()
  }, [setSelectedRole])

  const exportAsExcel = () => {
    if (data.length > 0) {
      let filteredData = data.map((item) => {
        return {
          'Customer Name': item.displayName,
          'Customer Email': item.email,
          'Customer Phone': item.phone,
          'Customer Address':
            item.address.address +
            ' ' +
            item.address.city +
            ' ' +
            item.address.region +
            ' ' +
            item.address.postalCode +
            ' ' +
            item.address.country,
          'Customer Status': item.status,
          'Is Employee': item.isEmployee ? 'Yes' : 'No',
          'Due Date': item.dueDate,
          'Is Account Verified': item.isAccountVerified ? 'Yes' : 'No',
          'Created At': item.createdAt,
          'Updated At': item.updatedAt,
        }
      })

      const ws = XLSX.utils.json_to_sheet(filteredData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SalesData')
      XLSX.writeFile(wb, 'customers_' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.xlsx')
    }
  }

  return (
    <div className='d-flex'>
      <button type='button' className='btn btn-secondary mr-2' onClick={exportAsExcel}>
        <KTIcon iconName='send' className='fs-2' />
        Export to Excel
      </button>

      <span className='mx-2'></span>
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Customer
      </button>
      {modalOpen && <AddNewCustomer close={closeModal} />}
    </div>
  )
}

export default TableToolbar
