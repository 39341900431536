import {FC, useState} from 'react'
import {Dialog} from '@headlessui/react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'

import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {User} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'

import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth'
import {toast} from 'react-toastify'
import ImageInput from '../../../shared/ImageInput'
import {countries} from '../../../../Constants'
import {useDispatch} from 'react-redux'
import {updateCustomer} from '../../../../redux/slicers/customerSlice'

const schema = Yup.object().shape({
  profilePicture: Yup.string(),
  fullname: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),

  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  phone: Yup.number().integer('Invalid number').min(7, 'Minimum 7 symbols'),
  username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
  address: Yup.string(),
  subcriptionplan: Yup.string(),
  status: Yup.string(),
  city: Yup.string(),
  region: Yup.string(),
  postalCode: Yup.string(),
  country: Yup.string(),
  password: Yup.string(),
})

const BaseUrl = process.env.REACT_APP_BACKEND_URL
const EditUserModal: FC<{user: User | undefined; close: () => void; isUserLoading: boolean}> = ({
  user,
  close,
  isUserLoading,
}) => {
  console.log(user)

  const {currentUser} = useAuth()
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const dispatch = useDispatch()

  const initialValues = {
    avatar: user?.profilePicture || blankImg,
    fullname: user?.displayName,
    email: user?.email,
    phone: user?.phone,
    username: user?.username,
    address: user?.address?.address,
    subcriptionplan: user?.plan,
    status: user?.status,
    city: user?.address?.city,
    region: user?.address?.region,
    postalCode: user?.address?.postalCode,
    country: user?.address?.country,
    password: '',
  }

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleFileChange = (file: File | null) => {
    setSelectedFile(file)
  }

  const filUpload = async ({image}) => {
    const formData = new FormData()
    formData.append('image', image)

    const result = await axios.post(
      `${BaseUrl}/file-upload/profile`,

      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return result.data.imageURL
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (selectedFile) {
          const imageURL = await filUpload({image: selectedFile})
          values.avatar = imageURL
        }

        await axios.patch(
          `${BaseUrl}/admin/customer/update-customer/${user?._id}`,
          {
            firstName: values.fullname?.split(' ')[0],
            lastName: values.fullname?.split(' ')[1],
            username: values.username,
            phone: values.phone,
            address: values.address,
            city: values.city,
            region: values.region,
            country: values.country,
            postalCode: values.postalCode,
            status: values.status,
            imageUrl: values.avatar,
            email: values.email,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )

        dispatch(updateCustomer(`${values.fullname} updated successfully`))
        toast.success('Client updated successfully')
      } catch (error: any) {
        console.log(error)
        toast.error('Client update failed')
      } finally {
        setSubmitting(true)
        close()
      }
    },
  })

  const [isLoading, setLoading] = useState(false)

  const sendNewPassword = async () => {
    try {
      setLoading(true)
      await axios
        .patch(`${BaseUrl}/admin/customer/change-user-password/${user?._id}`, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
          },
        })
        .then((response) => {
          toast.success('New password sent successfully')
        })
        .catch((error) => {
          toast.error('Failed to send new password')
        })
        .finally(() => {
          setLoading(false)
        })
    } catch (error: any) {
      toast.error('Failed to send new password')
    }
  }

  return (
    <>
      <Dialog
        className='modal fade show d-block'
        open
        onClose={close}
        tabIndex={-1}
        aria-modal='true'
      >
        <Dialog.Panel className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <Dialog.Title as='h2' className='fw-bolder'>
                Edit Customer Details - {user?.userIdentifier}
              </Dialog.Title>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={close}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form mw-100'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y px-5 px-lg-10'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
                    <div className='col-lg-8'>
                      <div>
                        <ImageInput
                          onChange={handleFileChange}
                          path={user?.profilePicture || blankImg}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Full Name</label>

                    <input
                      placeholder='Full name'
                      {...formik.getFieldProps('fullname')}
                      type='text'
                      name='fullname'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                        {
                          'is-valid': formik.touched.fullname && !formik.errors.fullname,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.fullname && formik.errors.fullname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fullname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>

                    <input
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Username</label>

                    <input
                      placeholder='Username'
                      {...formik.getFieldProps('username')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                          'is-valid': formik.touched.username && !formik.errors.username,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.username}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Phone</label>

                    <input
                      placeholder='Phone'
                      {...formik.getFieldProps('phone')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                        {
                          'is-valid': formik.touched.phone && !formik.errors.phone,
                        }
                      )}
                      type='email'
                      name='phone'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>City</label>
                    <input
                      placeholder='City'
                      {...formik.getFieldProps('city')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.city && formik.errors.city},
                        {
                          'is-valid': formik.touched.city && !formik.errors.city,
                        }
                      )}
                      type='text'
                      name='city'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.city}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Region</label>
                    <input
                      placeholder='Region'
                      {...formik.getFieldProps('region')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.region && formik.errors.region},
                        {
                          'is-valid': formik.touched.region && !formik.errors.region,
                        }
                      )}
                      type='text'
                      name='region'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.region && formik.errors.region && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.region}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Postal Code</label>
                    <input
                      placeholder='Postal Code'
                      {...formik.getFieldProps('postalCode')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.postalCode && formik.errors.postalCode},
                        {
                          'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
                        }
                      )}
                      type='text'
                      name='postalCode'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.postalCode}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Address</label>
                    <input
                      placeholder='Address'
                      {...formik.getFieldProps('address')}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.address && formik.errors.address},
                        {
                          'is-valid': formik.touched.address && !formik.errors.address,
                        }
                      )}
                      type='text'
                      name='address'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.address}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Country</label>
                    <select
                      aria-label='Select an Country'
                      data-control='select2'
                      data-placeholder='Select an Country...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {'is-invalid': formik.touched.country && formik.errors.country},
                        {'is-valid': formik.touched.country && !formik.errors.country}
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('country')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Country...
                      </option>
                      {countries.map((country, i) => (
                        <option data-kt-flag={country.flag} value={country.value} key={i}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.country && formik.errors.country && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.country}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Status</label>
                    <select
                      aria-label='Select an Address'
                      data-control='select2'
                      data-placeholder='Select an address...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {
                          'is-invalid': formik.touched.status && formik.errors.status,
                        },
                        {
                          'is-valid': formik.touched.status && !formik.errors.status,
                        }
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('status')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Status...
                      </option>
                      <option value='Active'>Active</option>
                      <option value='Deactive'>Inactive</option>
                    </select>
                    {formik.touched.status && formik.errors.status && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.status}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <button onClick={sendNewPassword} type='button' className='btn btn-primary'>
                    {isLoading ? (
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    ) : (
                      'Send New Password'
                    )}
                  </button>
                </div>

                <div className='text-center pt-15'>
                  <button
                    onClick={close}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={formik.isSubmitting || isUserLoading}
                  >
                    Discard
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isUserLoading || formik.isSubmitting}
                  >
                    <span className='indicator-label'>Submit</span>
                    {(formik.isSubmitting || isUserLoading) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default EditUserModal
