import {Column, HeaderProps} from 'react-table'
import {FC, PropsWithChildren, useEffect, useState} from 'react'
import {Menu} from '@headlessui/react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import EditUserModal from './EditUserModal'
import usePopup from '../../../../hooks/usePopup'
import {useAuth} from '../../../../app/modules/auth'
import axios from 'axios'
import {toast} from 'react-toastify'
import {renderBadge} from '../../../../helpers'
import {useDispatch} from 'react-redux'
import {deleteCustomer} from '../../../../redux/slicers/customerSlice'
import moment from 'moment'

type Customer = {
  _id: String
  displayName: string
  status: String
  email: string
  plan: string
  payment_method: string
  payment_method_type: string
  createdAt: string
  dueDate: string
  profilePicture: string
  userIdentifier: string
}

type TableHeaderProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Customer>>
}

type InfoCellProps = {
  user: any
}

const TableHeader: FC<TableHeaderProps> = ({className, title, tableProps}) => {
  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(className)}
      style={{cursor: 'pointer'}}
    >
      {title}
    </th>
  )
}

const UserInfoCell: FC<InfoCellProps> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        {user.profilePicture ? (
          <img src={user.profilePicture} alt='' />
        ) : (
          <span className='symbol-label bg-light-primary text-primary fw-bold'>
            {user.displayName.charAt(0).toUpperCase()}
          </span>
        )}
      </div>
      <Link to={'/customers/' + user._id} className='text-dark text-hover-primary'>
        {user.displayName}
      </Link>
    </div>
  )
}

const UserActionsCell: FC<{user: any}> = ({user}) => {
  const BaseUrl = process.env.REACT_APP_BACKEND_URL
  const {currentUser} = useAuth()
  const [trigger] = usePopup()

  const [modalOpen, setModalOpen] = useState(false)
  const [isUserLoading, setUserLoading] = useState(true)

  const closeModal = () => {
    setModalOpen(false)
  }

  const getUser = () => {
    setUserLoading(true)
    setTimeout(() => {
      setUserLoading(false)
    }, 1000)
  }

  useEffect(() => {
    getUser()
  }, [])

  const openEditModal = () => {
    console.log('open edit modal', user)
    setModalOpen(true)
  }

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const deleteClient = async (clientid) => {
    try {
      setLoading(true)
      await axios.delete(`${BaseUrl}/admin/customer/${clientid}`, {
        headers: {
          Authorization: `Bearer ${currentUser?.api_token}`,
        },
      })

      dispatch(deleteCustomer('delete-successful'))
      toast.success('Delete successful')
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        error.response.data.message === 'you-dont-have-permission-to-delete-client'
      ) {
        toast.error('Delete failed You dont have permission to delete client')
      } else {
        toast.error('Delete failed')
      }
    } finally {
      setLoading(false)
    }
  }

  const deleteItem = () => {
    trigger(
      'Delete',
      () => deleteClient(user._id),
      () => {}
    )
  }

  return (
    <>
      <Menu>
        {({open}) => (
          <>
            <Menu.Button
              className='btn btn-light btn-active-light-primary btn-sm'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              Actions
              <KTIcon iconName='down' className='fs-5 m-0' />
            </Menu.Button>
            <Menu.Items
              className={clsx(
                'menu menu-sub d-flex menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4',
                open && 'show'
              )}
              style={{
                position: 'absolute',
                zIndex: 105,
                right: 0,
              }}
            >
              <Menu.Item as='div' className='menu-item px-3'>
                <span className='menu-link px-3'>
                  <Link to={'/customers/' + user._id}>View</Link>
                </span>
              </Menu.Item>
              <Menu.Item as='div' className='menu-item px-3'>
                <span className='menu-link px-3' onClick={openEditModal}>
                  Edit
                </span>
              </Menu.Item>
              <Menu.Item as='div' className='menu-item px-3'>
                <span
                  className='menu-link px-3'
                  data-kt-users-table-filter='delete_row'
                  onClick={() => deleteItem()}
                >
                  Delete
                </span>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
      {modalOpen && <EditUserModal user={user} close={closeModal} isUserLoading={isUserLoading} />}
    </>
  )
}
const PaymentDetailsCell: FC<{user: Customer}> = ({user}) => {
  const method = user.payment_method_type
  const number = user.payment_method

  const renderCardImg = (type: string) => {
    let url = ''

    switch (type) {
      case 'visa':
        url = '/media/svg/card-logos/visa.svg'
        break
      case 'americanexpress':
        url = '/media/svg/card-logos/american-express.svg'
        break
      case 'mastercard':
        url = '/media/svg/card-logos/mastercard.svg'
        break

      default:
        url = '/media/svg/card-logos/visa.svg'
        break
    }

    return toAbsoluteUrl(url)
  }

  return (
    <div className='d-flex'>
      <img src={renderCardImg(method)} className='w-35px me-3' alt='' />
      <span className=''>{number}</span>
    </div>
  )
}

const columns: ReadonlyArray<Column<Customer>> = [
  {
    id: 'id',
    Header: (props) => <TableHeader tableProps={props} title='ID' className='min-w-50px' />,
    accessor: '_id',
    Cell: ({...props}) => {
      return <span>{props.data[props.row.index].userIdentifier}</span>
    },
  },
  {
    id: 'fullname',
    Header: (props) => <TableHeader tableProps={props} title='Customers' className='min-w-125px' />,
    accessor: 'displayName',
    Cell: ({...props}) => {
      return <UserInfoCell user={props.data[props.row.index]} />
    },
  },
  {
    id: 'status',
    Header: (props) => <TableHeader tableProps={props} title='status' className='min-w-100px' />,
    accessor: 'status',
    Cell: ({...props}) => {
      return (
        <span
          className={clsx('badge', renderBadge(props.data[props.row.index]?.status! as string))}
        >
          {props.data[props.row.index]?.status}
        </span>
      )
    },
  },
  {
    id: 'email',
    Header: (props) => <TableHeader tableProps={props} title='Email' className='min-w-100px' />,
    accessor: 'email',
  },
  {
    id: 'company',
    Header: (props) => <TableHeader tableProps={props} title='Plan' className='min-w-100px' />,
    accessor: 'plan',
  },
  {
    id: 'Payment Method',
    Header: (props) => (
      <TableHeader tableProps={props} title='Payment Method' className='min-w-125px' />
    ),
    accessor: 'payment_method',
    Cell: ({...props}) => {
      return (
        <>
          {props.data[props.row.index].plan === 'Free' ? (
            'N/A'
          ) : (
            <PaymentDetailsCell user={props.data[props.row.index]} />
          )}
        </>
      )
    },
  },
  {
    id: 'due date',
    Header: (props) => <TableHeader tableProps={props} title='Due Date' className='min-w-100px' />,
    accessor: 'dueDate',
    Cell: ({...props}) => {
      return (
        <span>
          {props.data[props.row.index].plan === 'Free'
            ? 'N/A'
            : moment(props.data[props.row.index].dueDate).format('DD/MM/YYYY')}
        </span>
      )
    },
  },
  {
    id: 'created date',
    Header: (props) => (
      <TableHeader tableProps={props} title='Created Date' className='min-w-100px' />
    ),
    accessor: 'createdAt',
    Cell: ({...props}) => {
      return <span>{moment(props.data[props.row.index].createdAt).format('DD/MM/YYYY')}</span>
    },
  },

  {
    Header: (props) => (
      <TableHeader tableProps={props} title='Actions' className='text-end min-w-125px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell user={props.data[props.row.index]} />,
  },
]

export {columns}
