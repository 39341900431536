import {Link} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dialog} from '@headlessui/react'
import {useState} from 'react'

const UserMenu = () => {
  const {currentUser, logout} = useAuth()
  let [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px symbol-circle me-5'>
              {currentUser?.pic ? (
                <img alt='Logo' src={toAbsoluteUrl(currentUser?.pic)} />
              ) : (
                <span className='symbol-label bg-light-primary text-primary fw-bold'>
                  {currentUser?.fullname?.charAt(0).toUpperCase()}
                </span>
              )}
            </div>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.fullname}
              </div>
              <p className='fw-bold text-muted text-hover-primary fs-7'>{currentUser?.email}</p>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to={'/profile'} className='menu-link px-5'>
            My Profile
          </Link>
        </div>

        <div className='menu-item px-5'>
          <a
            type='button'
            className='menu-link px-5'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            Log Out
          </a>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Logout</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></div>
            </div>
            <div className='modal-body'>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                data-bs-dismiss='modal'
                className='btn btn-danger'
                onClick={() => {
                  logout()
                }}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserMenu
