import {createSlice} from '@reduxjs/toolkit'

export interface PermissionState {
  message: string
}

const initialState: PermissionState = {
  message: '',
}

const permissionStateSlice = createSlice({
  name: 'permissionState',
  initialState,
  reducers: {
    addPermission: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updatePermission: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deletePermission: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addPermission, updatePermission, deletePermission} = permissionStateSlice.actions

export default permissionStateSlice.reducer
