export const countries = [
  {flag: 'flags/afghanistan.svg', value: 'AF', name: 'Afghanistan'},
  {flag: 'flags/aland-islands.svg', value: 'AX', name: 'Aland Islands'},
  {flag: 'flags/albania.svg', value: 'AL', name: 'Albania'},
  {flag: 'flags/algeria.svg', value: 'DZ', name: 'Algeria'},
  {flag: 'flags/american-samoa.svg', value: 'AS', name: 'American Samoa'},
  {flag: 'flags/andorra.svg', value: 'AD', name: 'Andorra'},
  {flag: 'flags/angola.svg', value: 'AO', name: 'Angola'},
  {flag: 'flags/anguilla.svg', value: 'AI', name: 'Anguilla'},
  {flag: 'flags/antigua-and-barbuda.svg', value: 'AG', name: 'Antigua and Barbuda'},
  {flag: 'flags/argentina.svg', value: 'AR', name: 'Argentina'},
  {flag: 'flags/armenia.svg', value: 'AM', name: 'Armenia'},
  {flag: 'flags/aruba.svg', value: 'AW', name: 'Aruba'},
  {flag: 'flags/australia.svg', value: 'AU', name: 'Australia'},
  {flag: 'flags/austria.svg', value: 'AT', name: 'Austria'},
  {flag: 'flags/azerbaijan.svg', value: 'AZ', name: 'Azerbaijan'},
  {flag: 'flags/bahamas.svg', value: 'BS', name: 'Bahamas'},
  {flag: 'flags/bahrain.svg', value: 'BH', name: 'Bahrain'},
  {flag: 'flags/bangladesh.svg', value: 'BD', name: 'Bangladesh'},
  {flag: 'flags/barbados.svg', value: 'BB', name: 'Barbados'},
  {flag: 'flags/belarus.svg', value: 'BY', name: 'Belarus'},
  {flag: 'flags/belgium.svg', value: 'BE', name: 'Belgium'},
  {flag: 'flags/belize.svg', value: 'BZ', name: 'Belize'},
  {flag: 'flags/benin.svg', value: 'BJ', name: 'Benin'},
  {flag: 'flags/bermuda.svg', value: 'BM', name: 'Bermuda'},
  {flag: 'flags/bhutan.svg', value: 'BT', name: 'Bhutan'},
  {flag: 'flags/bolivia.svg', value: 'BO', name: 'Bolivia, Plurinational State of'},
  {flag: 'flags/bonaire.svg', value: 'BQ', name: 'Bonaire, Sint Eustatius and Saba'},
  {flag: 'flags/bosnia-and-herzegovina.svg', value: 'BA', name: 'Bosnia and Herzegovina'},
  {flag: 'flags/botswana.svg', value: 'BW', name: 'Botswana'},
  {flag: 'flags/brazil.svg', value: 'BR', name: 'Brazil'},
  {
    flag: 'flags/british-indian-ocean-territory.svg',
    value: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {flag: 'flags/brunei.svg', value: 'BN', name: 'Brunei Darussalam'},
  {flag: 'flags/bulgaria.svg', value: 'BG', name: 'Bulgaria'},
  {flag: 'flags/burkina-faso.svg', value: 'BF', name: 'Burkina Faso'},
  {flag: 'flags/burundi.svg', value: 'BI', name: 'Burundi'},
  {flag: 'flags/cambodia.svg', value: 'KH', name: 'Cambodia'},
  {flag: 'flags/cameroon.svg', value: 'CM', name: 'Cameroon'},
  {flag: 'flags/canada.svg', value: 'CA', name: 'Canada'},
  {flag: 'flags/cape-verde.svg', value: 'CV', name: 'Cape Verde'},
  {flag: 'flags/cayman-islands.svg', value: 'KY', name: 'Cayman Islands'},
  {flag: 'flags/central-african-republic.svg', value: 'CF', name: 'Central African Republic'},
  {flag: 'flags/chad.svg', value: 'TD', name: 'Chad'},
  {flag: 'flags/chile.svg', value: 'CL', name: 'Chile'},
  {flag: 'flags/china.svg', value: 'CN', name: 'China'},
  {flag: 'flags/christmas-island.svg', value: 'CX', name: 'Christmas Island'},
  {flag: 'flags/cocos-island.svg', value: 'CC', name: 'Cocos (Keeling) Islands'},
  {flag: 'flags/colombia.svg', value: 'CO', name: 'Colombia'},
  {flag: 'flags/comoros.svg', value: 'KM', name: 'Comoros'},
  {flag: 'flags/cook-islands.svg', value: 'CK', name: 'Cook Islands'},
  {flag: 'flags/costa-rica.svg', value: 'CR', name: 'Costa Rica'},
  {flag: 'flags/ivory-coast.svg', value: 'CI', name: "Côte d'Ivoire"},
  {flag: 'flags/croatia.svg', value: 'HR', name: 'Croatia'},
  {flag: 'flags/cuba.svg', value: 'CU', name: 'Cuba'},
  {flag: 'flags/curacao.svg', value: 'CW', name: 'Curaçao'},
  {flag: 'flags/czech-republic.svg', value: 'CZ', name: 'Czech Republic'},
  {flag: 'flags/denmark.svg', value: 'DK', name: 'Denmark'},
  {flag: 'flags/djibouti.svg', value: 'DJ', name: 'Djibouti'},
  {flag: 'flags/dominica.svg', value: 'DM', name: 'Dominica'},
  {flag: 'flags/dominican-republic.svg', value: 'DO', name: 'Dominican Republic'},
  {flag: 'flags/ecuador.svg', value: 'EC', name: 'Ecuador'},
  {flag: 'flags/egypt.svg', value: 'EG', name: 'Egypt'},
  {flag: 'flags/el-salvador.svg', value: 'SV', name: 'El Salvador'},
  {flag: 'flags/equatorial-guinea.svg', value: 'GQ', name: 'Equatorial Guinea'},
  {flag: 'flags/eritrea.svg', value: 'ER', name: 'Eritrea'},
  {flag: 'flags/estonia.svg', value: 'EE', name: 'Estonia'},
  {flag: 'flags/ethiopia.svg', value: 'ET', name: 'Ethiopia'},
  {flag: 'flags/falkland-islands.svg', value: 'FK', name: 'Falkland Islands (Malvinas)'},
  {flag: 'flags/fiji.svg', value: 'FJ', name: 'Fiji'},
  {flag: 'flags/finland.svg', value: 'FI', name: 'Finland'},
  {flag: 'flags/france.svg', value: 'FR', name: 'France'},
  {flag: 'flags/french-polynesia.svg', value: 'PF', name: 'French Polynesia'},
  {flag: 'flags/gabon.svg', value: 'GA', name: 'Gabon'},
  {flag: 'flags/gambia.svg', value: 'GM', name: 'Gambia'},
  {flag: 'flags/georgia.svg', value: 'GE', name: 'Georgia'},
  {flag: 'flags/germany.svg', value: 'DE', name: 'Germany'},
  {flag: 'flags/ghana.svg', value: 'GH', name: 'Ghana'},
  {flag: 'flags/gibraltar.svg', value: 'GI', name: 'Gibraltar'},
  {flag: 'flags/greece.svg', value: 'GR', name: 'Greece'},
  {flag: 'flags/greenland.svg', value: 'GL', name: 'Greenland'},
  {flag: 'flags/grenada.svg', value: 'GD', name: 'Grenada'},
  {flag: 'flags/guam.svg', value: 'GU', name: 'Guam'},
  {flag: 'flags/guatemala.svg', value: 'GT', name: 'Guatemala'},
  {flag: 'flags/guernsey.svg', value: 'GG', name: 'Guernsey'},
  {flag: 'flags/guinea.svg', value: 'GN', name: 'Guinea'},
  {flag: 'flags/guinea-bissau.svg', value: 'GW', name: 'Guinea-Bissau'},
  {flag: 'flags/haiti.svg', value: 'HT', name: 'Haiti'},
  {flag: 'flags/vatican-city.svg', value: 'VA', name: 'Holy See (Vatican City State)'},
  {flag: 'flags/honduras.svg', value: 'HN', name: 'Honduras'},
  {flag: 'flags/hong-kong.svg', value: 'HK', name: 'Hong Kong'},
  {flag: 'flags/hungary.svg', value: 'HU', name: 'Hungary'},
  {flag: 'flags/iceland.svg', value: 'IS', name: 'Iceland'},
  {flag: 'flags/india.svg', value: 'IN', name: 'India'},
  {flag: 'flags/indonesia.svg', value: 'ID', name: 'Indonesia'},
  {flag: 'flags/iran.svg', value: 'IR', name: 'Iran, Islamic Republic of'},
  {flag: 'flags/iraq.svg', value: 'IQ', name: 'Iraq'},
  {flag: 'flags/ireland.svg', value: 'IE', name: 'Ireland'},
  {flag: 'flags/isle-of-man.svg', value: 'IM', name: 'Isle of Man'},
  {flag: 'flags/israel.svg', value: 'IL', name: 'Israel'},
  {flag: 'flags/italy.svg', value: 'IT', name: 'Italy'},
  {flag: 'flags/jamaica.svg', value: 'JM', name: 'Jamaica'},
  {flag: 'flags/japan.svg', value: 'JP', name: 'Japan'},
  {flag: 'flags/jersey.svg', value: 'JE', name: 'Jersey'},
  {flag: 'flags/jordan.svg', value: 'JO', name: 'Jordan'},
  {flag: 'flags/kazakhstan.svg', value: 'KZ', name: 'Kazakhstan'},
  {flag: 'flags/kenya.svg', value: 'KE', name: 'Kenya'},
  {flag: 'flags/kiribati.svg', value: 'KI', name: 'Kiribati'},
  {flag: 'flags/north-korea.svg', value: 'KP', name: "Korea, Democratic People's Republic of"},
  {flag: 'flags/kuwait.svg', value: 'KW', name: 'Kuwait'},
  {flag: 'flags/kyrgyzstan.svg', value: 'KG', name: 'Kyrgyzstan'},
  {flag: 'flags/laos.svg', value: 'LA', name: "Lao People's Democratic Republic"},
  {flag: 'flags/latvia.svg', value: 'LV', name: 'Latvia'},
  {flag: 'flags/lebanon.svg', value: 'LB', name: 'Lebanon'},
  {flag: 'flags/lesotho.svg', value: 'LS', name: 'Lesotho'},
  {flag: 'flags/liberia.svg', value: 'LR', name: 'Liberia'},
  {flag: 'flags/libya.svg', value: 'LY', name: 'Libya'},
  {flag: 'flags/liechtenstein.svg', value: 'LI', name: 'Liechtenstein'},
  {flag: 'flags/lithuania.svg', value: 'LT', name: 'Lithuania'},
  {flag: 'flags/luxembourg.svg', value: 'LU', name: 'Luxembourg'},
  {flag: 'flags/macao.svg', value: 'MO', name: 'Macao'},
  {flag: 'flags/madagascar.svg', value: 'MG', name: 'Madagascar'},
  {flag: 'flags/malawi.svg', value: 'MW', name: 'Malawi'},
  {flag: 'flags/malaysia.svg', value: 'MY', name: 'Malaysia'},
  {flag: 'flags/maldives.svg', value: 'MV', name: 'Maldives'},
  {flag: 'flags/mali.svg', value: 'ML', name: 'Mali'},
  {flag: 'flags/malta.svg', value: 'MT', name: 'Malta'},
  {flag: 'flags/marshall-island.svg', value: 'MH', name: 'Marshall Islands'},
  {flag: 'flags/martinique.svg', value: 'MQ', name: 'Martinique'},
  {flag: 'flags/mauritania.svg', value: 'MR', name: 'Mauritania'},
  {flag: 'flags/mauritius.svg', value: 'MU', name: 'Mauritius'},
  {flag: 'flags/mexico.svg', value: 'MX', name: 'Mexico'},
  {flag: 'flags/micronesia.svg', value: 'FM', name: 'Micronesia, Federated States of'},
  {flag: 'flags/moldova.svg', value: 'MD', name: 'Moldova, Republic of'},
  {flag: 'flags/monaco.svg', value: 'MC', name: 'Monaco'},
  {flag: 'flags/mongolia.svg', value: 'MN', name: 'Mongolia'},
  {flag: 'flags/montenegro.svg', value: 'ME', name: 'Montenegro'},
  {flag: 'flags/montserrat.svg', value: 'MS', name: 'Montserrat'},
  {flag: 'flags/morocco.svg', value: 'MA', name: 'Morocco'},
  {flag: 'flags/mozambique.svg', value: 'MZ', name: 'Mozambique'},
  {flag: 'flags/myanmar.svg', value: 'MM', name: 'Myanmar'},
  {flag: 'flags/namibia.svg', value: 'NA', name: 'Namibia'},
  {flag: 'flags/nauru.svg', value: 'NR', name: 'Nauru'},
  {flag: 'flags/nepal.svg', value: 'NP', name: 'Nepal'},
  {flag: 'flags/netherlands.svg', value: 'NL', name: 'Netherlands'},
  {flag: 'flags/new-zealand.svg', value: 'NZ', name: 'New Zealand'},
  {flag: 'flags/nicaragua.svg', value: 'NI', name: 'Nicaragua'},
  {flag: 'flags/niger.svg', value: 'NE', name: 'Niger'},
  {flag: 'flags/nigeria.svg', value: 'NG', name: 'Nigeria'},
  {flag: 'flags/niue.svg', value: 'NU', name: 'Niue'},
  {flag: 'flags/norfolk-island.svg', value: 'NF', name: 'Norfolk Island'},
  {flag: 'flags/northern-mariana-islands.svg', value: 'MP', name: 'Northern Mariana Islands'},
  {flag: 'flags/norway.svg', value: 'NO', name: 'Norway'},
  {flag: 'flags/oman.svg', value: 'OM', name: 'Oman'},
  {flag: 'flags/pakistan.svg', value: 'PK', name: 'Pakistan'},
  {flag: 'flags/palau.svg', value: 'PW', name: 'Palau'},
  {flag: 'flags/palestine.svg', value: 'PS', name: 'Palestinian Territory, Occupied'},
  {flag: 'flags/panama.svg', value: 'PA', name: 'Panama'},
  {flag: 'flags/papua-new-guinea.svg', value: 'PG', name: 'Papua New Guinea'},
  {flag: 'flags/paraguay.svg', value: 'PY', name: 'Paraguay'},
  {flag: 'flags/peru.svg', value: 'PE', name: 'Peru'},
  {flag: 'flags/philippines.svg', value: 'PH', name: 'Philippines'},
  {flag: 'flags/poland.svg', value: 'PL', name: 'Poland'},
  {flag: 'flags/portugal.svg', value: 'PT', name: 'Portugal'},
  {flag: 'flags/puerto-rico.svg', value: 'PR', name: 'Puerto Rico'},
  {flag: 'flags/qatar.svg', value: 'QA', name: 'Qatar'},
  {flag: 'flags/romania.svg', value: 'RO', name: 'Romania'},
  {flag: 'flags/russia.svg', value: 'RU', name: 'Russian Federation'},
  {flag: 'flags/rwanda.svg', value: 'RW', name: 'Rwanda'},
  {flag: 'flags/st-barts.svg', value: 'BL', name: 'Saint Barthélemy'},
  {flag: 'flags/saint-kitts-and-nevis.svg', value: 'KN', name: 'Saint Kitts and Nevis'},
  {flag: 'flags/st-lucia.svg', value: 'LC', name: 'Saint Lucia'},
  {flag: 'flags/sint-maarten.svg', value: 'MF', name: 'Saint Martin (French part)'},
  {
    flag: 'flags/st-vincent-and-the-grenadines.svg',
    value: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {flag: 'flags/samoa.svg', value: 'WS', name: 'Samoa'},
  {flag: 'flags/san-marino.svg', value: 'SM', name: 'San Marino'},
  {flag: 'flags/sao-tome-and-prince.svg', value: 'ST', name: 'Sao Tome and Principe'},
  {flag: 'flags/saudi-arabia.svg', value: 'SA', name: 'Saudi Arabia'},
  {flag: 'flags/senegal.svg', value: 'SN', name: 'Senegal'},
  {flag: 'flags/serbia.svg', value: 'RS', name: 'Serbia'},
  {flag: 'flags/seychelles.svg', value: 'SC', name: 'Seychelles'},
  {flag: 'flags/sierra-leone.svg', value: 'SL', name: 'Sierra Leone'},
  {flag: 'flags/singapore.svg', value: 'SG', name: 'Singapore'},
  {flag: 'flags/sint-maarten.svg', value: 'SX', name: 'Sint Maarten (Dutch part)'},
  {flag: 'flags/slovakia.svg', value: 'SK', name: 'Slovakia'},
  {flag: 'flags/slovenia.svg', value: 'SI', name: 'Slovenia'},
  {flag: 'flags/solomon-islands.svg', value: 'SB', name: 'Solomon Islands'},
  {flag: 'flags/somalia.svg', value: 'SO', name: 'Somalia'},
  {flag: 'flags/south-africa.svg', value: 'ZA', name: 'South Africa'},
  {flag: 'flags/south-korea.svg', value: 'KR', name: 'South Korea'},
  {flag: 'flags/south-sudan.svg', value: 'SS', name: 'South Sudan'},
  {flag: 'flags/spain.svg', value: 'ES', name: 'Spain'},
  {flag: 'flags/sri-lanka.svg', value: 'LK', name: 'Sri Lanka'},
  {flag: 'flags/sudan.svg', value: 'SD', name: 'Sudan'},
  {flag: 'flags/suriname.svg', value: 'SR', name: 'Suriname'},
  {flag: 'flags/swaziland.svg', value: 'SZ', name: 'Swaziland'},
  {flag: 'flags/sweden.svg', value: 'SE', name: 'Sweden'},
  {flag: 'flags/switzerland.svg', value: 'CH', name: 'Switzerland'},
  {flag: 'flags/syria.svg', value: 'SY', name: 'Syrian Arab Republic'},
  {flag: 'flags/taiwan.svg', value: 'TW', name: 'Taiwan, Province of China'},
  {flag: 'flags/tajikistan.svg', value: 'TJ', name: 'Tajikistan'},
  {flag: 'flags/tanzania.svg', value: 'TZ', name: 'Tanzania, United Republic of'},
  {flag: 'flags/thailand.svg', value: 'TH', name: 'Thailand'},
  {flag: 'flags/togo.svg', value: 'TG', name: 'Togo'},
  {flag: 'flags/tokelau.svg', value: 'TK', name: 'Tokelau'},
  {flag: 'flags/tonga.svg', value: 'TO', name: 'Tonga'},
  {flag: 'flags/trinidad-and-tobago.svg', value: 'TT', name: 'Trinidad and Tobago'},
  {flag: 'flags/tunisia.svg', value: 'TN', name: 'Tunisia'},
  {flag: 'flags/turkey.svg', value: 'TR', name: 'Turkey'},
  {flag: 'flags/turkmenistan.svg', value: 'TM', name: 'Turkmenistan'},
  {flag: 'flags/turks-and-caicos.svg', value: 'TC', name: 'Turks and Caicos Islands'},
  {flag: 'flags/tuvalu.svg', value: 'TV', name: 'Tuvalu'},
  {flag: 'flags/uganda.svg', value: 'UG', name: 'Uganda'},
  {flag: 'flags/ukraine.svg', value: 'UA', name: 'Ukraine'},
  {flag: 'flags/united-arab-emirates.svg', value: 'AE', name: 'United Arab Emirates'},
  {flag: 'flags/united-kingdom.svg', value: 'GB', name: 'United Kingdom'},
  {flag: 'flags/united-states.svg', value: 'US', name: 'United States'},
  {flag: 'flags/uruguay.svg', value: 'UY', name: 'Uruguay'},
  {flag: 'flags/uzbekistan.svg', value: 'UZ', name: 'Uzbekistan'},
  {flag: 'flags/vanuatu.svg', value: 'VU', name: 'Vanuatu'},
  {flag: 'flags/venezuela.svg', value: 'VE', name: 'Venezuela, Bolivarian Republic of'},
  {flag: 'flags/vietnam.svg', value: 'VN', name: 'Vietnam'},
  {flag: 'flags/virgin-islands.svg', value: 'VI', name: 'Virgin Islands'},
  {flag: 'flags/yemen.svg', value: 'YE', name: 'Yemen'},
  {flag: 'flags/zambia.svg', value: 'ZM', name: 'Zambia'},
  {flag: 'flags/zimbabwe.svg', value: 'ZW', name: 'Zimbabwe'},
]
