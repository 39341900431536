import {useEffect, useState, FC} from 'react'
import * as Yup from 'yup'
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import {toast} from 'react-toastify'
import OTPInput from 'react-otp-input'

const initialValues = {}

const forgotPasswordSchema = Yup.object().shape({})

const OTPForm: FC = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams(location.search)
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const id = token

  const navigate = useNavigate()

  const verifyToken = async () => {
    await axios
      .get(`${BaseUrl}/auth/forgot-password/verify/${id}`)
      .then((response) => {})
      .catch((error) => {
        console.log(error)
        navigate('/auth/error-otp')
      })
  }

  useEffect(() => {
    verifyToken()
  }, [])

  const [isEmailSent, setIsEmailSent] = useState(false)

  const resendOTP = async () => {
    try {
      setIsEmailSent(true)
      await axios
        .post(`${BaseUrl}/auth/forgot-password`, {
          email: email,
          type: 'admin',
        })
        .then((response) => {
          toast.success('Sent password reset. Please check your email')

          navigate(`/auth/otp/verification?token=${response.data.data.token}&email=${email}`, {
            replace: true,
          })
        })
    } catch (error: any) {
      toast.error(error.response.data.message)
      return error
    } finally {
      setIsEmailSent(false)
    }
  }

  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(false)
  const BaseUrl = process.env.REACT_APP_BACKEND_URL

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)

        await axios
          .post(`${BaseUrl}/auth/verify-otp/${id}`, {
            otp: parseInt(otp),
          })
          .then((response) => {
            toast.success('OTP verified successfully')

            formik.resetForm()
            navigate(`/auth/new-password/verification/${response.data.token}`, {
              replace: true,
            })
          })
      } catch (error: any) {
        toast.error(error.response.data.message)
        return error
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-11'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/Zealmart Light Logo.svg')}
            className='h-50px mb-12'
          />
          <h1 className='text-dark fw-bolder mb-3'>OTP</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            OTP has been sent to your email! Please check your email and enter the OTP below
          </div>
        </div>
        <div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputType='text'
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={'d-flex justify-content-center gap-2 w-100 pb-5'}
            inputStyle={{
              width: '4rem',
              height: '4rem',
              border: '1px solid #E5E7EB',
              borderRadius: '0.375rem',
              fontSize: '1.5rem',
              color: '#1F2937',
              outline: 'none',
              margin: '0 0.25rem',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: '500',
            }}
          />
        </div>

        <div className='pb-lg-0 d-flex flex-column flex-center gap-4'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary w-100'
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
          <div>
            <p>
              Didn't receive the OTP?{' '}
              <Link
                to='#'
                onClick={() => {
                  resendOTP()
                }}
              >
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-light text-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {isEmailSent ? (
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : (
                    'Resend OTP'
                  )}
                </button>
              </Link>
            </p>
          </div>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light text-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Return to Log in
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
export default OTPForm
