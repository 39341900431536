import React, {useEffect, useState} from 'react'
import {CustomTable} from '../../../../components/dashboard/CustomTable/CustomersTable/CustomersTable'
import {getUsers} from '../../../../api/clients'
import {useAuth} from '../../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../redux/reduxStore'

const Customers = () => {
  const {currentUser} = useAuth()
  const customerOnChange = useSelector((state: RootState) => state.customer)

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    setLoading(true)

    getUsers(currentUser?.api_token as string)
      .then((result: any) => {
        const users = result.data || []
        setData(users)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false)
      })
  }, [customerOnChange])

  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xxl-12'>
        <CustomTable data={data} isLoading={isLoading} />
      </div>
    </div>
  )
}

export default Customers
