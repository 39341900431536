import {createSlice} from '@reduxjs/toolkit'

export interface SubscribedCustomerState {
  message: string
}

const initialState: SubscribedCustomerState = {
  message: '',
}

const subscribedCustomerStateSlice = createSlice({
  name: 'subscribedCustomerState',
  initialState,
  reducers: {
    addSubscribedCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateSubscribedCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteSubscribedCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addSubscribedCustomer, updateSubscribedCustomer, deleteSubscribedCustomer} =
  subscribedCustomerStateSlice.actions

export default subscribedCustomerStateSlice.reducer
