import {configureStore} from '@reduxjs/toolkit'
import customerReducer from './slicers/customerSlice'
import subscriptionReducer from './slicers/subscriptionSlice'
import subscribedcustomerReducer from './slicers/subscribedcustomerSlice'
import couponCodeReducer from './slicers/couponcodeSlice'
import systemUserReducer from './slicers/systemuserSlice'
import roleReducer from './slicers/roleSlice'
import permissionReducer from './slicers/permissionSlice'
import refundReducer from './slicers/refundSlice'
import profileReducer from './slicers/profileSlice'

export const store = configureStore({
  reducer: {
    customer: customerReducer,
    subscription: subscriptionReducer,
    subscribedcustomer: subscribedcustomerReducer,
    couponcode: couponCodeReducer,
    systemuser: systemUserReducer,
    role: roleReducer,
    permission: permissionReducer,
    refund: refundReducer,
    profile: profileReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
