import Swal from 'sweetalert2'

function usePopup() {
  const trigger = (
    title: string = 'delete',
    confirmCallback: () => void,
    rejectCallback: () => void,
    text?: string
  ) => {
    Swal.fire({
      title,
      text: text || 'Are you sure you want to delete this?',
      icon: 'warning',
      confirmButtonText: `Yes, ${title}!`,
      cancelButtonText: 'No, Cancel',
      showCancelButton: true,
      customClass: {
        cancelButton: 'btn fw-bold btn-active-light-primary',
        confirmButton: 'btn fw-bold btn-danger',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmCallback()
      } else {
        rejectCallback()
      }
    })
  }

  return [trigger]
}

export default usePopup
