/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'

import {LuCrown} from 'react-icons/lu'

type Props = {
  className: string
  series: ApexOptions['series']
  labels?: ApexOptions['labels']
  categories: any[]
  title: string
  subtitle: string
}

const PurchaseSummaryChart: React.FC<Props> = ({
  className,
  series,
  labels,
  title,
  subtitle,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, series, labels, categories)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-center justify-content-center flex-row'>
          <div className='symbol symbol-circle symbol-35px me-2'>
            <div className='symbol-label bg-gray-300'>
              <span className='fs4 text-warning'>
                <LuCrown />
              </span>
              {/* <KTIcon iconName='crown' iconType='solid' className='fs-4 text-warning' /> */}
            </div>
          </div>
          <span className='d-inline-block whitespace-nowrap card-label fw-bold fs-5'>{title}</span>
        </h3>
      </div>
      <div className='card-body d-flex align-items-center justify-content-center'>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '250px'}} />
      </div>
    </div>
  )
}

export {PurchaseSummaryChart}

function getChartOptions(
  height: number,
  series: ApexOptions['series'],
  labels: ApexOptions['labels'],
  categories: any
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color1 = '#FB8424'
  const color2 = '#FFA359'
  const color3 = '#FECEA7'

  return {
    series,
    chart: {
      fontFamily: 'inherit',
      type: 'radialBar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          total: {
            show: true,
            label: 'Total',
            color: labelColor,
            formatter: function () {
              return labels ? labels[0] : ''
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [color1, color2, color3],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    labels: labels,
  }
}
