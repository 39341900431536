import clsx from 'clsx'
import React, {FC, useState, ChangeEvent} from 'react'
import {KTIcon} from '../../_metronic/helpers'

const ImageInput: FC<{
  className?: string
  path: string
  onChange?: (file: File | null) => void
}> = ({className, path, onChange}) => {
  const [image, setImage] = useState<string | null>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files

    if (files && files.length > 0) {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && typeof e.target.result === 'string') {
          setImage(e.target.result)

          // Call the callback function with the selected file
          if (onChange) {
            onChange(files[0])
          }
        }
      }

      reader.readAsDataURL(files[0])
    }
  }

  const handleRemoveFile = () => {
    setImage(null)

    // Call the callback function with null when removing the file
    if (onChange) {
      onChange(null)
    }
  }

  return (
    <div
      className={clsx(
        'image-input image-input-outline',
        className,
        image ? '' : 'image-input-changed'
      )}
      data-kt-image-input='true'
      style={{
        backgroundImage: `url(${path})`, // Use the provided path as the default image
      }}
    >
      <div
        className='image-input-wrapper w-125px h-125px'
        style={{backgroundImage: `url(${image || path})`}}
      ></div>
      <label
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='change'
        data-bs-toggle='tooltip'
        aria-label='Change avatar'
        data-bs-original-title='Change avatar'
        data-kt-initialized='1'
      >
        <i className='ki-duotone ki-pencil fs-7'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>

        <input type='file' name='avatar' accept='image/*' onChange={handleFileChange} />
        <input type='hidden' name='avatar_remove' value='1' />
      </label>
      <span
        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        aria-label='Remove avatar'
        data-kt-image-input-action='remove'
        data-bs-original-title='Remove avatar'
        data-kt-initialized='1'
        onClick={handleRemoveFile}
      >
        <KTIcon iconName='cross' className='fs-2' />
      </span>
    </div>
  )
}

export default ImageInput
