import {FC, useEffect, useState} from 'react'
import {Dialog} from '@headlessui/react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import ImageInput from '../../../shared/ImageInput'
import {toast} from 'react-toastify'
import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth'
import {countries} from '../../../../Constants'
import {addCustomer} from '../../../../redux/slicers/customerSlice'
import {useDispatch} from 'react-redux'
import PhoneInput from 'react-phone-input-2'

type sub = {
  _id: string
  name: string
}

const schema = Yup.object().shape({
  fullname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Fullname is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.number()
    .integer('Invalid number')
    .min(7, 'Minimum 7 symbols')
    .required('Phone is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('username is required'),
  address: Yup.string().required('Address is required'),
  subcriptionplan: Yup.string().required('Subcription plan required'),
  status: Yup.string().required('Status plan required'),
  country: Yup.string().required('Country required'),
  city: Yup.string().required('City required'),
  region: Yup.string().required('Region required'),
  postalCode: Yup.string().required('Postal code required'),
})

const initialValues = {
  profilePicture: '',
  fullname: '',
  email: '',
  phone: '',
  username: '',
  address: '',
  country: '',
  subcriptionplan: '',
  status: '',
  city: '',
  region: '',
  postalCode: '',
}

const AddNewCustomer: FC<{close: () => void}> = ({close}) => {
  const dispatch = useDispatch()

  const {currentUser} = useAuth()
  const BaseUrl = process.env.REACT_APP_BACKEND_URL
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()

  const handleFileSelection = (e) => {
    setFile(e)
  }

  const [subscription, setSubscription] = useState<sub[]>([])

  const GetSubscriptions = async () => {
    axios
      .get(`${BaseUrl}/admin/subscription/view`, {
        headers: {
          Authorization: `Bearer ${currentUser?.api_token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data)
        setSubscription(response.data.data)
      })
  }

  useEffect(() => {
    GetSubscriptions()
  }, [])

  const filUpload = async ({image}) => {
    const formData = new FormData()
    formData.append('image', image)

    const result = await axios.post(`${BaseUrl}/file-upload/profile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return result.data.imagePath
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        let uploadedImage = ''
        if (file) {
          uploadedImage = await filUpload({image: file})
        }
        const response = await axios.post(
          `${BaseUrl}/admin/customer`,
          {
            name: values.fullname,
            address: values.address,
            email: values.email,
            phone: values.phone,
            username: values.username,
            country: values.country,
            city: values.city,
            region: values.region,
            postalCode: values.postalCode,
            imageUrl: uploadedImage,
            subscription: values.subcriptionplan,
            status: values.status,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.api_token}`,
            },
          }
        )
        console.log(response)
        dispatch(addCustomer('add-successful'))

        toast.success('Customer added successfully')
      } catch (error: any) {
        toast.error(error.response.data.message)
      } finally {
        setSubmitting(false)
        setLoading(false)
        close()
      }
    },
  })

  return (
    <>
      <Dialog
        className='modal fade show d-block'
        open
        onClose={close}
        tabIndex={-1}
        aria-modal='true'
      >
        <Dialog.Panel className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <Dialog.Title as='h2' className='fw-bolder'>
                Add Customer
              </Dialog.Title>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={close}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
            </div>
            <div className='modal-body scroll-y my-7'>
              <form
                id='kt_modal_add_user_form'
                className='form mw-100'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div
                  className='d-flex flex-column scroll-y px-5 px-lg-10'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-7 pt-6'>
                    <ImageInput
                      path={formik.values.profilePicture}
                      onChange={handleFileSelection}
                    />
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Full Name</label>
                    <input
                      placeholder='Full name'
                      {...formik.getFieldProps('fullname')}
                      type='text'
                      name='fullname'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.fullname && formik.errors.fullname},
                        {
                          'is-valid': formik.touched.fullname && !formik.errors.fullname,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.fullname && formik.errors.fullname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fullname}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Email input */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>

                    <input
                      placeholder='Email Address'
                      {...formik.getFieldProps('email')}
                      type='email'
                      name='email'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* phonenumber input  */}
                  <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-dark fs-6'>Mobile Number</label>

                    <PhoneInput
                      value={formik.values.phone}
                      onChange={(value) => formik.setFieldValue('phone', value)}
                      inputClass={clsx('form-control w-100 form-control-solid')}
                      country='lk'
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.phone}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Username input  */}
                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Username</label>
                    <input
                      placeholder='Username'
                      {...formik.getFieldProps('username')}
                      type='text'
                      name='username'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.username && formik.errors.username},
                        {
                          'is-valid': formik.touched.username && !formik.errors.username,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.username}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Address  */}

                  <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Addres</label>
                    <input
                      placeholder='Address'
                      {...formik.getFieldProps('address')}
                      type='text'
                      name='address'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.address && formik.errors.address},
                        {
                          'is-valid': formik.touched.address && !formik.errors.address,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.address}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* City */}
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>City</label>
                    <input
                      placeholder='City'
                      {...formik.getFieldProps('city')}
                      type='text'
                      name='city'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.city && formik.errors.city},
                        {
                          'is-valid': formik.touched.city && !formik.errors.city,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.city}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Region */}
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Region</label>
                    <input
                      placeholder='Region'
                      {...formik.getFieldProps('region')}
                      type='text'
                      name='region'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.region && formik.errors.region},
                        {
                          'is-valid': formik.touched.region && !formik.errors.region,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.region && formik.errors.region && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.region}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Postal Code */}
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Postal Code</label>
                    <input
                      placeholder='Postal Code'
                      {...formik.getFieldProps('postalCode')}
                      type='text'
                      name='postalCode'
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.postalCode && formik.errors.postalCode},
                        {
                          'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting}
                    />
                    {formik.touched.postalCode && formik.errors.postalCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.postalCode}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Country</label>
                    <select
                      aria-label='Select an Country'
                      data-control='select2'
                      data-placeholder='Select an country...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {'is-invalid': formik.touched.country && formik.errors.country},
                        {'is-valid': formik.touched.country && !formik.errors.country}
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('country')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Country...
                      </option>
                      {countries.map((country, i) => (
                        <option data-kt-flag={country.flag} value={country.value} key={i}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.country && formik.errors.country && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.country}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Subcription Plan */}
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Subcription Plan</label>
                    <select
                      aria-label='Select an subcriptionplan'
                      data-control='select2'
                      data-placeholder='Select an subcriptionplan'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {
                          'is-invalid':
                            formik.touched.subcriptionplan && formik.errors.subcriptionplan,
                        },
                        {
                          'is-valid':
                            formik.touched.subcriptionplan && !formik.errors.subcriptionplan,
                        }
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('subcriptionplan')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select an subcription plan...
                      </option>
                      {subscription.map((subscription) => (
                        <option key={subscription?._id} value={subscription?._id}>
                          {subscription?.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.address}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* status */}
                  <div className='row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Customer Status</label>
                    <select
                      aria-label='Select a Status'
                      data-control='select2'
                      data-placeholder='Select an status...'
                      className={clsx(
                        'form-select form-select-solid form-select-lg fw-semibold select2-hidden-accessible',
                        {
                          'is-invalid': formik.touched.status && formik.errors.status,
                        },
                        {
                          'is-valid': formik.touched.status && !formik.errors.status,
                        }
                      )}
                      data-select2-id='select2-data-9-py72'
                      tabIndex={-1}
                      aria-hidden='true'
                      data-kt-initialized={1}
                      {...formik.getFieldProps('status')}
                    >
                      <option value='' data-select2-id='select2-data-11-0ljl'>
                        Select a Status...
                      </option>
                      <option value='Active'>Active</option>
                      <option value='Deactive'>Deactive</option>
                    </select>
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.address}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='text-center pt-15'>
                  <button
                    onClick={close}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      formik.handleSubmit()
                    }}
                    data-kt-users-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    <span className='indicator-label'>Submit</span>
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default AddNewCustomer
