import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import {toast} from 'react-toastify'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const BaseUrl = process.env.REACT_APP_BACKEND_URL

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        await axios
          .post(`${BaseUrl}/auth/forgot-password`, {
            email: values.email,
            type: 'admin',
          })
          .then((response) => {
            toast.success('Sent password reset. Please check your email')
            navigate(
              `/auth/otp/verification?token=${response.data.data.token}&email=${values.email}`,
              {
                replace: true,
              }
            )
          })
      } catch (error: any) {
        toast.error(error.response.data.message)
        return error
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-11'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/Zealmart Light Logo.svg')}
            className='h-50px mb-12'
          />
          <h1 className='text-dark fw-bolder mb-3'>Forgot your password</h1>
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email address and we'll send you a link to reset your password.
          </div>
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email Address</label>
          <input
            type='email'
            placeholder='Enter your email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='pb-lg-0 d-flex flex-column flex-center gap-4'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary w-100'
            disabled={loading} // Disable the button when loading is true
          >
            {loading ? (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light text-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Return to Log in
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
