/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuCustom = () => {
  return (
    <>
      <SidebarMenuItem to='/dashboard' icon='element-11' title='Home' fontIcon='bi-layers' />
      <SidebarMenuItem to='/customers' icon='abstract-38' title='Customers' fontIcon='ki-outline' />
      <SidebarMenuItemWithSub
        to='/subscriptions'
        icon='map'
        title='Subscriptions'
        fontIcon='ki-outline'
      >
        <SidebarMenuItem
          to='/subscriptions/subscriptions-list'
          title='Subscriptions List'
          hasBullet={true}
        />
        <SidebarMenuItem to='/subscriptions/coupons' title='Coupon Codes' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/user-management'
        title='User Management'
        fontIcon='ki-outline'
        icon='abstract-28'
      >
        <SidebarMenuItemWithSub to='/user-management/users' title='Users' hasBullet={true}>
          <SidebarMenuItem to='/user-management/users' title='User List' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/user-management/roles' title='Roles' hasBullet={true}>
          <SidebarMenuItem to='/user-management/roles' title='Roles List' hasBullet={true} />
        </SidebarMenuItemWithSub>
        {/* <SidebarMenuItem
          to='/user-management/permissions'
          title='Permission Manager'
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/notifications'
        icon='notification'
        title='Notifications'
        fontIcon='ki-outline'
      />
      <SidebarMenuItem to='/refunds' icon='chart-pie-3' title='Refunds' fontIcon='ki-outline' />
    </>
  )
}

export {SidebarMenuCustom}
