import React from 'react'

type Props = {
  className: string
  total: string
  color: string
  title: string
  subtitle: string
  description: string
  progress: string
}

const StatsNew: React.FC<Props> = ({
  className,
  total,
  color,
  title,
  subtitle,
  description,
  progress,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body my-3'>
        <div className='py-1'>
          <span className='text-dark fs-1 fw-bold me-2'>{total}</span>

          <span className={`card-title fw-bold text-muted fs-7 mb-3 d-block`}>{description}</span>
        </div>

        <div className='d-flex flex-column mt-17'>
          <span className='fw-bold text-dark fs-6'>{subtitle}</span>
          <div className={`progress h-7px bg-success bg-opacity-50 mt-1`}>
            <div
              className={`progress-bar bg-success-active`}
              role='progressbar'
              style={{width: parseInt(progress)}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {StatsNew}
