import {Link} from 'react-router-dom'

export function SecurityError() {
  return (
    <>
      <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
        <div className='py-20'>
          <div className='text-center'>
            <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
              <div className='alert alert-danger w-25'>
                <div className='d-flex flex-column align-items-center'>
                  <h4 className='alert-heading'>Something went wrong</h4>
                  <p className='font-weight-bold text-center'>
                    The specified Session could not be found. This error is usually caused by using
                    the wrong API key or visiting an expired Session. Please make sure the Session
                    is not expired and that the API keys used to initialize Zealmart and create the
                    Session from the same account.
                  </p>
                  <div>
                    <Link to='/auth/login'>Return to Log in</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
