export const renderBadge = (status: string) => {
  let _badge: JSX.Element | string = ''

  switch (status.toLowerCase()) {
    case 'approved':
    case 'active':
    case 'activated':
    case 'refunded':
      _badge = 'badge badge-light-success'
      break
    case 'pending':
    case 'expiring':
    case 'used':
      _badge = 'badge badge-light-warning'
      break
    case 'rejected':
    case 'suspended':
    case 'deactivated':
    case 'expired':
    case 'cancelled':
      _badge = 'badge badge-light-danger'
      break
    case 'in progress':
      _badge = 'badge badge-light-info'
      break

    default:
      _badge = 'badge badge-light-warning'
      break
  }

  return _badge
}
