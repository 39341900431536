import {createSlice} from '@reduxjs/toolkit'

export interface ProfileState {
  message: string
}

const initialState: ProfileState = {
  message: '',
}

const profileStateSlice = createSlice({
  name: 'profileState',
  initialState,
  reducers: {
    addProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteProfile: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addProfile, updateProfile, deleteProfile} = profileStateSlice.actions

export default profileStateSlice.reducer
