import axios, {AxiosResponse} from 'axios'
import {UsersQueryResponse} from '../app/modules/apps/user-management/users-list/core/_models'

const BaseUrl = process.env.REACT_APP_BACKEND_URL

const getUsers = async (apiToken: string | undefined): Promise<UsersQueryResponse> => {
  try {
    const response: AxiosResponse<UsersQueryResponse> = await axios.get(
      `${BaseUrl}/admin/customer`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export {getUsers}
