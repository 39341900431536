/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProfitChart} from '../../../components/dashboard/CustomCharts/ProfitChart'
import {PurchaseSummaryChart} from '../../../components/dashboard/CustomCharts/PurchaseSummaryChart'
import {StatsNew} from '../../../components/dashboard/CustomWidgets/StatsNew'
import {StatsAlt} from '../../../components/dashboard/CustomWidgets/StatsAlt'
import MixedWidget from '../../../components/dashboard/CustomWidgets/MixedWidget'
import {subscriptionRevenu} from '../../../api/_requests'
import {useAuth} from '../../modules/auth'

import Customers from './Customers/Customers'
import axios from 'axios'

const HomePage: FC = () => {
  const [isLoading, setLoading] = useState(false)

  const {currentUser} = useAuth()

  const [substat, setSubstat] = useState<any[]>([])
  const subRevenue = async () => {
    await subscriptionRevenu(currentUser?.api_token as string).then((result: any) => {
      console.log('result', result.data)
      setSubstat(result.data)
    })
  }

  const [salesTotal, setSalesTotal] = useState<number>(0)

  const getTotalSales = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/total-sales`, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
          },
        })
        .then((response) => {
          setSalesTotal(response.data.data)
        })
    } catch (error) {
      throw error
    }
  }

  const [newCustomersData, setNewCustomers] = useState<any[]>([])

  const newCustomers = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/new-customers`, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
          },
        })
        .then((response) => {
          setNewCustomers(response.data.data)
          console.log('new customers', response.data.data)
        })
    } catch (error) {
      throw error
    }
  }

  type Revenue = {
    totalRevenue?: number
    evrydayRevenue: {date: string; revenue: number}[]
  }

  const [weeklyRevenue, setWeeklyRevenue] = useState<Revenue>()

  const weekRevenue = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/revenue-week`, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
          },
        })
        .then((response) => {
          setWeeklyRevenue(response.data.data)
          console.log('week revenue', response.data.data)
        })
    } catch (error) {
      throw error
    }
  }

  type Revenu30Day = {
    totalRevenue?: number
    evrydayRevenue: {date: string; revenue: number}[]
  }

  const [day30Revenue, set30dayRevenue] = useState<Revenu30Day>()

  const monthRevenue = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/monthly-revenue`, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
          },
        })
        .then((response) => {
          set30dayRevenue(response.data.data)
          console.log('month revenue', response.data.data)
        })
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    setLoading(true)
    subRevenue()
    getTotalSales()
    newCustomers()
    weekRevenue()
    monthRevenue()
    setLoading(false)
  }, [currentUser?.api_token])

  return (
    <>
      {isLoading === true ? (
        <></>
      ) : (
        <>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-8 col-lg-8 col-xl-8 col-xxl-9 d-flex flex-column justify-content-between'>
              <div className='row'>
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 pe-4 mb-2 mb-md-4'>
                  <StatsNew
                    className='card-xl-strech h-100'
                    color=''
                    title=''
                    subtitle='1000 to goal'
                    description='Total Sales'
                    total={`${salesTotal}`}
                    progress={`${salesTotal}`}
                  />
                </div>
                <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 ps-4 mb-2 mb-md-4'>
                  <StatsAlt
                    className='card-xl-strech h-100'
                    color=''
                    title=''
                    subtitle=''
                    description='New Customers This Month'
                    total={`${newCustomersData.length}`}
                    profilePictures={
                      newCustomersData.length > 0
                        ? newCustomersData.map((item) =>
                            item.profilePicture
                              ? item.profilePicture
                              : `https://ui-avatars.com/api/?name=${
                                  item.displayName.split(' ')[0]
                                }+${
                                  item.displayName.split(' ')[1]
                                }&size=128&rounded=true&font-size=0.33&bold=true&length=1&uppercase=true&format=svg&rounded=true&bold=true&background=random&color=fff`
                          )
                        : []
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                  <ProfitChart
                    className='mb-0 mb-xl-8'
                    series={[
                      {
                        name: 'Daily Revenue',
                        data: day30Revenue?.evrydayRevenue.map((item) => item.revenue) || [0],
                      },
                    ]}
                    categories={[]}
                    title='Sales This Month'
                    subtitle=''
                    totalAmount={
                      `${(day30Revenue as {totalRevenue?: number})?.totalRevenue}` || `0`
                    }
                    target='Users from all packages'
                  />
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-3 d-flex flex-column justify-content-between'>
              <PurchaseSummaryChart
                className='card-xl-strech mb-2 mb-md-4 mb-xl-8 h-100 h-xl-auto'
                series={substat.map(
                  (item) => (item.count / substat.reduce((a, b) => a + b.count, 0)) * 100
                )}
                categories={substat.map((item) => item.subscription)}
                title='Subscription Plans Purchased'
                subtitle=''
                labels={substat.map((item) => item.subscription)}
              />
              <MixedWidget
                className='card-xl-strech mb-xl-8 h-100'
                title='Total Revenue'
                description='Total revenue generated this week'
                series={
                  (
                    weeklyRevenue as {evrydayRevenue: {date: string; revenue: number}[]}
                  )?.evrydayRevenue.map((item) => item.revenue) || [0]
                }
                amount={`${(weeklyRevenue as {totalRevenue?: number})?.totalRevenue}`}
                months={[]}
              />
            </div>

            <div className='col-xxl-12 mt-4'>
              <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                <div className='col-xxl-12'>
                  <Customers />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Home: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <HomePage />
    </>
  )
}

export default Home
