import {createSlice} from '@reduxjs/toolkit'

export interface CustomerState {
  message: string
}

const initialState: CustomerState = {
  message: '',
}

const customerStateSlice = createSlice({
  name: 'customerState',
  initialState,
  reducers: {
    addCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteCustomer: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addCustomer, updateCustomer, deleteCustomer} = customerStateSlice.actions
export default customerStateSlice.reducer
