import {createSlice} from '@reduxjs/toolkit'

export interface SubscriptionState {
  message: string
}

const initialState: SubscriptionState = {
  message: '',
}

const subscriptionStateSlice = createSlice({
  name: 'subscriptionState',
  initialState,
  reducers: {
    addSubscription: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateSubscription: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteSubscription: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addSubscription, updateSubscription, deleteSubscription} =
  subscriptionStateSlice.actions
export default subscriptionStateSlice.reducer
