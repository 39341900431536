import {createSlice} from '@reduxjs/toolkit'

export interface RoleState {
  message: string
}

const initialState: RoleState = {
  message: '',
}

const roleStateSlice = createSlice({
  name: 'roleState',
  initialState,
  reducers: {
    addRole: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateRole: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteRole: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addRole, updateRole, deleteRole} = roleStateSlice.actions

export default roleStateSlice.reducer
