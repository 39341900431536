import {Link} from 'react-router-dom'

export function SuccessFull() {
  return (
    <>
      <div className='d-flex flex-column align-items-center justify-content-center min-vh-100'>
        <div className='py-20'>
          <div className='text-center'>
            <h1 className='display-1 text-success'>🎉</h1>
            <h1 className='display-4'>Success!</h1>

            <p className='text-muted'>Your account has been created successfully.</p>

            <Link to='/login' className='btn btn-primary'>
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
