import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ThemeModeSwitcher} from '../../../_metronic/partials'
import UserMenu from './UserMenu'
import {useAuth} from '../../../app/modules/auth'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {NotificationsProps} from '../../../app/pages/profile/Notifications'
import moment from 'moment'
import {Link} from 'react-router-dom'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {currentUser} = useAuth()
  const BaseUrl = process.env.REACT_APP_BACKEND_URL
  const [notifications, setNotifications] = useState<NotificationsProps[]>([])
  const getNotifications = async () => {
    try {
      await axios
        .get(`${BaseUrl}/notifications/`)
        .then((response) => {
          setNotifications(response.data.data)
        })
        .catch((error) => {})
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('position-relative', btnClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='notification' className={btnIconClass} />
          {/* <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' /> */}
        </div>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold menu-column w-350px w-lg-375px'
          data-kt-menu='true'
        >
          <div
            className='d-flex flex-column bgi-no-repeat rounded-top'
            style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
          >
            <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
              Notifications{' '}
              <span className='fs-8 opacity-75 ps-3'>
                {`${notifications.length}`} Notifications
              </span>
            </h3>
            <Link
              to='/notifications'
              className='btn btn-sm btn-custom btn-primary'
              onClick={() => {
                document.body.click()
                getNotifications()
              }}
            >
              View All
            </Link>
          </div>
          <div className='card-body'>
            <div className='my-5 px-8'>
              {notifications.map((alert, index) => (
                <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span className={clsx('symbol-label', `bg-light-${alert.clientid}`)}>
                        {' '}
                        <KTIcon
                          iconName={'cloud-change'}
                          className={`fs-2 text-${alert.clientid}`}
                        />
                      </span>
                    </div>

                    <div className='mb-0 me-2'>
                      <p className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                        {alert.subject}
                      </p>
                      <div className='text-gray-400 fs-7'>{alert.message}</div>
                      <span className='badge badge-light fs-8 mt-2'>
                        {moment(alert.createdAt).format('DD/MM/YYYY HH:mm')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol symbol-circle', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {currentUser?.pic ? (
            <img alt='Logo' src={toAbsoluteUrl(currentUser?.pic)} />
          ) : (
            <span className='symbol-label bg-light-primary text-primary fw-bold'>
              {currentUser?.fullname?.charAt(0).toUpperCase()}
            </span>
          )}{' '}
          <p className='text-muted text-hover-primary fw-bolder fs-6 d-none d-md-inline '>
            {currentUser?.fullname}
          </p>
        </div>

        <UserMenu />
      </div>
    </div>
  )
}

export default Navbar
