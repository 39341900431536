/* eslint-disable jsx-a11y/anchor-is-valid */

import {useEffect, useMemo} from 'react'
import {Outlet, Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const params = useParams()

  const isForgotPasswordRoute = useMemo(
    () => Object.values(params).includes('forgot-password'),
    [params]
  )

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column h-100 flex-center flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid w-100'>
          {/* begin::Wrapper */}
          <div className='w-100 w-lg-500px p-4 p-lg-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        {/* <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div> */}
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      {isForgotPasswordRoute ? null : (
        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            <Link to='/' className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Zealmart Light Logo.svg')}
                className='h-50px mb-12'
              />
            </Link>
            {/* end::Logo */}

            {/* begin::Image */}
            <img
              className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
              src={toAbsoluteUrl('/media/misc/auth-screens.png')}
              alt=''
            />
            {/* end::Image */}

            {/* begin::Title */}
            <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
              Unified Supply Chain Solutions: Connecting All Stakeholders
            </h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='text-white fs-base text-center'>
              Welcome to our supply chain hub, where distributors, manufacturers, and sellers unite
              for efficiency. Experience seamless operations and enhanced collaboration as we
              redefine supply chain management. Join us for growth and success.
            </div>
            {/* end::Text */}
          </div>
          {/* end::Content */}
        </div>
      )}
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
