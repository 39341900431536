import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

import {AiFillAppstore} from 'react-icons/ai'
import {DiGoogleDrive} from 'react-icons/di'
import {BiHomeAlt2, BiSolidUser} from 'react-icons/bi'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const SubscriptionIcon = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        d='M3.94979 0.699951C3.87202 0.699935 3.79558 0.720073 3.72791 0.758402C3.66024 0.796731 3.60366 0.851942 3.56369 0.918651L0.863689 5.41865C0.81576 5.49872 0.793911 5.59172 0.801167 5.68476C0.808423 5.7778 0.844425 5.86628 0.904189 5.93795L7.64879 14.0317C7.69825 14.0933 7.76305 14.1408 7.83669 14.1694C7.91033 14.198 7.99021 14.2067 8.06829 14.1947C8.14637 14.1827 8.21991 14.1503 8.28151 14.1008C8.3431 14.0513 8.39059 13.9865 8.41919 13.9129L11.4576 6.09995H14.9604L15.0954 5.93795C15.1552 5.86628 15.1912 5.7778 15.1984 5.68476C15.2057 5.59172 15.1838 5.49872 15.1359 5.41865L12.4359 0.918651C12.3959 0.851942 12.3393 0.796731 12.2717 0.758402C12.204 0.720073 12.1276 0.699935 12.0498 0.699951H3.94979ZM10.4928 6.09995L7.99979 12.508L5.50769 6.09995H10.4919H10.4928ZM2.04359 5.19995L4.20359 1.59995H5.98469L4.54469 5.19995H2.04359ZM4.54109 6.09995L6.58139 11.3461L2.21099 6.09995H4.54109ZM5.51309 5.19995L6.95309 1.59995H9.04379L10.4838 5.19995H5.51579H5.51309ZM11.4531 5.19995L10.0131 1.59995H11.7942L13.9542 5.19995H11.4531ZM12.9489 10.6C13.4263 10.6 13.8841 10.4103 14.2217 10.0727C14.5592 9.73518 14.7489 9.27734 14.7489 8.79995C14.7489 8.32256 14.5592 7.86472 14.2217 7.52716C13.8841 7.18959 13.4263 6.99995 12.9489 6.99995C12.4715 6.99995 12.0137 7.18959 11.6761 7.52716C11.3385 7.86472 11.1489 8.32256 11.1489 8.79995C11.1489 9.27734 11.3385 9.73518 11.6761 10.0727C12.0137 10.4103 12.4715 10.6 12.9489 10.6ZM12.9489 15.1C15.1989 15.1 16.0989 13.9705 16.0989 12.85C16.0989 12.4919 15.9567 12.1485 15.7035 11.8954C15.4503 11.6422 15.1069 11.5 14.7489 11.5H11.1489C10.7908 11.5 10.4475 11.6422 10.1943 11.8954C9.94112 12.1485 9.79889 12.4919 9.79889 12.85C9.79889 13.975 10.6989 15.1 12.9489 15.1Z'
        fill='currentColor'
      />
    </svg>
  )

  const renderIcon = (icon: string) => {
    let _icon: JSX.Element | string = ''

    switch (icon.toLowerCase()) {
      case 'home':
        _icon = <BiHomeAlt2 />
        break
      case 'customers':
        _icon = <BiSolidUser />
        break
      case 'subscriptions':
        _icon = <SubscriptionIcon />
        break

      default:
        break
    }

    return _icon
  }

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon fs-2'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
            {/* {renderIcon(title)} */}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
