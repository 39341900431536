import {createSlice} from '@reduxjs/toolkit'

export interface SystemUserState {
  message: string
}

const initialState: SystemUserState = {
  message: '',
}

const systemUserStateSlice = createSlice({
  name: 'systemUserState',
  initialState,
  reducers: {
    addSystemUser: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
    updateSystemUser: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },

    deleteSystemUser: (state, action) => {
      state.message = action.payload.message + ' ' + new Date().getTime()
    },
  },
})

export const {addSystemUser, updateSystemUser, deleteSystemUser} = systemUserStateSlice.actions
export default systemUserStateSlice.reducer
